.CleaningButton {
  max-width: 1340px;
  margin: 0 auto;
  position: relative;
  top: -95px;
  z-index: 1001;
  justify-content: flex-end;
  display: flex;

  .MuiButtonBase-root {
    margin: 0 0 0 12px;
    background-color: #FFFFFF;
    width: 429px;
    height: 90px;

    &:hover {
      border: 2px solid #003156;
      background-color: #FFFFFF;
    }

  }

  .regular-cleaning {
    width: 80px;
  }

  .cleaning-after {
    width: 80px;
  }

  .office-cleaning {
    width: 80px;
  }

  .span__name {
    width: 200px;
    height: 75px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    margin-left: 10px;
    background: #F1F9FF;

    h6 {
      margin: 0;
      color: #003156;
      text-align: center;
      font-family: 'Zen Antique', serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }

  }

  @media only screen and (min-width: 600px) and (max-width: 1439px) {
    position: revert;
    justify-content: end;
    margin-right: 30px;
    margin-top: -20px;


    .MuiButtonBase-root {
      margin: 0 3px;
      width: 180px;
      height: 67px;
      min-height: 60px;

      .regular-cleaning {
        width: 50px;
      }

      .cleaning-after {
        width: 50px;
      }

      .office-cleaning {
        width: 50px;
      }

    }
    .span__name {
      width: 120px;
      height: 60px;


      h6 {
        font-size: 13px;

      }

    }

  }





  @media only screen and (max-width: 599px) {
    justify-content: flex-end;
    margin-right: 50px;
    top: -92.5px;

    .MuiButtonBase-root {
      display: none;
      margin: 0 3px;
      width: 35px;
      height: 35px;
      min-width: 35px;

      .regular-cleaning {
        min-width: 30px;

        padding: 5px;
      }

      .cleaning-after {
        width: 30px;
        padding: 5px;
      }

      .office-cleaning {
        width: 35px;
      }

    }
    .span__name {
      display: none;
      margin: 0 0 0 5px;
      width: 90px;
      height: 40px;



      h6 {
        padding: 5px;
        font-size: 10px;

      }

    }
  }

}




