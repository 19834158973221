.Footer {
  background-color: #003156;
  height: auto;
}
.Footer .newsLetter {
  font-family: "Roboto Light", sans-serif;
  margin: 30px auto 0;
  max-width: 1440px;
  padding: 0 50px;
}
.Footer .newsLetter h3 {
  margin: 0;
  color: var(--Pure-White, #FFF);
  text-align: left;
  font-family: "Work Sans", sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.96px;
  text-transform: capitalize;
}
.Footer .newsLetter p {
  font-family: "Work Sans", sans-serif;
  justify-content: start;
  margin: 7px 0 0;
  padding: 0;
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 138.889% */
  letter-spacing: -0.54px;
  text-transform: capitalize;
}
.Footer .newsLetter p .Subscribe {
  align-items: center;
  display: grid;
}
.Footer .newsLetter .InputSubscription {
  margin-top: 30px;
  align-items: center;
  display: grid;
}
.Footer .Section {
  max-width: 1440px;
  padding: 0 50px;
  margin: 30px auto;
}
.Footer .Section .logo {
  margin: 0;
  max-width: 477px;
  height: 149px;
}
.Footer .Section .logo img {
  margin-left: -25px;
  justify-content: end;
  width: 100%;
}
.Footer .Section .link {
  justify-content: center;
  display: flex;
}
.Footer .Section .links {
  margin-top: 50px;
  display: grid;
}
.Footer .Section .links hr {
  width: 100px;
  height: 1px;
  text-align: left;
  margin: 15px 0 25px;
}
.Footer .Section .links h4 {
  color: #FFF;
  font-family: "Work Sans", sans-serif;
  justify-content: start;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Footer .Section .links a {
  font-family: "Work Sans", sans-serif;
  height: 24px;
  margin-bottom: 27px;
  align-items: center;
  display: flex;
  text-decoration: none;
  color: #BCBCBC;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Footer .Section .links a:hover {
  color: #FFFFFF;
  cursor: pointer;
}
.Footer .social__networks {
  justify-content: center;
  margin: 0 50px;
  color: #BCBCBC;
  display: flex;
}
.Footer .social__networks .network {
  width: 50px;
}
.Footer .social__networks .network:hover {
  color: #FFFFFF;
  cursor: pointer;
}
.Footer .Copyright {
  margin-top: 100px;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.Footer .Copyright p {
  font-family: "Work Sans", sans-serif;
  min-width: 365.217px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.48px;
  text-transform: capitalize;
  color: var(--Pure-White, #FFF);
}
.Footer .Copyright hr {
  width: 100%;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgba(168, 193, 255, 0.15);
  margin: 1em 0;
  padding: 0;
}
@media only screen and (max-width: 599px) {
  .Footer .newsLetter {
    margin: 15px auto 0;
    max-width: 599px;
    padding: 0 10px;
  }
  .Footer .newsLetter h3 {
    font-size: 26px;
  }
  .Footer .newsLetter p {
    font-size: 14px;
  }
  .Footer .newsLetter p .Subscribe {
    align-items: center;
    display: grid;
  }
  .Footer .newsLetter .InputSubscription {
    margin-top: 30px;
    align-items: center;
    display: grid;
  }
  .Footer .Section {
    max-width: 599px;
    padding: 0 10px;
    margin: 15px auto;
  }
  .Footer .Section .logo {
    margin: 0 auto;
    max-width: 350px;
    height: 100px;
  }
  .Footer .Section .logo img {
    margin-left: 0;
  }
  .Footer .Section .link {
    margin: 0;
    min-width: 100wh;
  }
  .Footer .Section .links {
    margin: 0;
    display: grid;
  }
  .Footer .Section .links hr {
    justify-content: center;
    display: flex;
    width: 100%;
    height: 1px;
    text-align: center;
    margin: 5px 0 10px;
  }
  .Footer .Section .links h4 {
    justify-content: center;
    color: #FFF;
    font-family: "Work Sans", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .Footer .Section .links a {
    justify-content: center;
    margin-left: 20px;
    font-family: "Work Sans", sans-serif;
    height: 24px;
    margin-bottom: 27px;
    align-items: center;
    display: flex;
    text-decoration: none;
    color: #BCBCBC;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .Footer .Section .Services a {
    margin-left: 0;
    justify-content: center !important;
  }
  .Footer .Copyright {
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .Footer .Copyright p {
    min-width: 220px;
    font-size: 12px;
  }
}

