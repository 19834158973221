body {
  scroll-behavior: smooth !important;
  margin: 0;
  padding: 0;
  @import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@700&family=Poppins:wght@600&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Zen+Antique&family=Zen+Dots&display=swap');

}

code {

}

*, html {

  scroll-behavior: smooth !important;
}
