.Footer {
  background-color: #003156;
  height: auto;


  .newsLetter {

    font-family: 'Roboto Light', sans-serif;
    margin: 30px auto 0;
    max-width: 1440px;
    padding: 0 50px;

    h3 {
      margin: 0;
      color: var(--Pure-White, #FFF);
      text-align: left;
      font-family: 'Work Sans', sans-serif;
      font-size: 32px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.96px;
      text-transform: capitalize;
    }

    p {
      font-family: 'Work Sans', sans-serif;
      justify-content: start;
      margin: 7px 0 0;
      padding: 0;
      color: #FFF;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px; /* 138.889% */
      letter-spacing: -0.54px;
      text-transform: capitalize;

      .Subscribe {
        align-items: center;
        display: grid;
      }
    }

    .InputSubscription {
      margin-top: 30px;
      align-items: center;
      display: grid;
    }

  }

  .Section {
    max-width: 1440px;
    padding: 0 50px;


    margin: 30px auto;

    .logo {

      margin: 0;
      max-width: 477px;
      height: 149px;

      img {
        margin-left: -25px;
        justify-content: end;
        width: 100%;
      }
    }

    .link {
      justify-content: center;
      display: flex;
    }

    .links {
      margin-top: 50px;
      display: grid;

      hr {
        width: 100px;
        height: 1px;
        text-align: left;
        margin: 15px 0 25px;

      }

      h4 {
        color: #FFF;
        font-family: 'Work Sans', sans-serif;
        justify-content: start;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      a {
        font-family: 'Work Sans', sans-serif;
        height: 24px;
        margin-bottom: 27px;
        align-items: center;
        display: flex;
        text-decoration: none;

        color: #BCBCBC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &:hover {
          color: #FFFFFF;
          cursor: pointer;
        }
      }
    }


  }

  .social__networks {
    justify-content: center;

    margin: 0 50px;
    color: #BCBCBC;
    display: flex;

    .network {
      width: 50px;

      &:hover {
        color: #FFFFFF;
        cursor: pointer;
      }

    }

  }

  .Copyright {
    margin-top: 100px;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
    display: flex;

    p {
      font-family: 'Work Sans', sans-serif;
      min-width: 365.217px;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.48px;
      text-transform: capitalize;
      color: var(--Pure-White, #FFF);

    }

    hr {
      width: 100%;
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid rgba(168, 193, 255, 0.15);
      margin: 1em 0;
      padding: 0;
    }

  }

  @media only screen and (max-width: 599px) {
    .newsLetter {
      margin: 15px auto 0;
      max-width: 599px;
      padding: 0 10px;

      h3 {
        font-size: 26px;
      }

      p {
        font-size: 14px;

        .Subscribe {
          align-items: center;
          display: grid;
        }
      }

      .InputSubscription {
        margin-top: 30px;
        align-items: center;
        display: grid;
      }

    }
    .Section {
      max-width: 599px;
      padding: 0 10px;


      margin: 15px auto;

      .logo {
        margin: 0 auto;
        max-width: 350px;
        height: 100px;

        img {
          margin-left: 0;
        }
      }

      .link {
        margin: 0;
        min-width: 100wh;


      }

      .links {
        margin: 0;
        display: grid;

        hr {
          justify-content: center;
          display: flex;
          width: 100%;
          height: 1px;
          text-align: center;
          margin: 5px 0 10px;

        }

        h4 {
          justify-content: center;
          color: #FFF;
          font-family: 'Work Sans', sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        a {
          justify-content: center;
          margin-left: 20px;
          font-family: 'Work Sans', sans-serif;
          height: 24px;
          margin-bottom: 27px;
          align-items: center;
          display: flex;
          text-decoration: none;

          color: #BCBCBC;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

        }

      }

      .Services{
        a{
          margin-left: 0;
          justify-content: center !important;
        }
      }
    }
    .Copyright {
      margin-top: 30px;
      margin-bottom: 15px;

      p {
        min-width: 220px;
        font-size: 12px;
      }
    }
  }
}
