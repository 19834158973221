.Cleaning__container {
  margin: 0 auto;
  width: 100%;

  .background {
    position: absolute;
    width: 100%;
    height: 103px;
    background: linear-gradient(180deg, rgba(43, 95, 137, 0.66) 0%, rgba(15, 133, 226, 0.00) 100%);
  }

  .logo {
    max-width: 1440px;
    display: flex;
    padding: 0 50px;
    margin: 0 auto;

    .logo__img {
      z-index: 10;
      width: 420px;
      height: 139px;
      margin: 39px 0 0;

      background-color: #003156;
    }

    span {
      z-index: 10;
      display: inline-flex;
      width: 100%;
      height: 139px;
      margin: 39px 0 0 124px;
      background-color: #003156;


      h4 {
        display: flex;
        margin-left: 60px;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: #EED170;
        text-align: center;
        font-family: 'Frank Ruhl Libre', serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .type_img {
        margin: -30px 0 0 -70px;
        position: absolute;
        width: 155px;
        height: 155px;
        transform: rotate(-0.145deg);
        border-radius: 154.632px;
        border: 1px solid #EDD070;
        object-fit: cover;
      }
    }
  }

  .rooms {
    max-width: 1540px;
    padding: 0 50px ;
    justify-content: center;
    display: flex;
    margin: 50px auto 0;
    .left{
      justify-content: flex-start;
    }
    .center{
      justify-content: center;
    }
    .right{
      justify-content: right;
    }
    .room {
      display: flex;

      div {
        width: 430px;
        height: 492px;
        border: 1px solid #003156;
        border-radius: 5px;
        transition: 1s;
        &:hover {
          transform: scale(1.03);
        }

        span {
          background-color: #FFFFFF;
          margin-top: -40px;
          margin-left: 10px;
          position: absolute;
          display: inline-flex;
          width: 100px;
          height: 100px;
          border: 1px solid #003156;
          border-radius: 50%;
          justify-content: center;
          align-items: center;

        }

        h5 {
          margin: 13px 0 0 128px;
          color: #003156;
          text-align: center;
          font-family: 'Zen Dots', cursive;
          font-size: 32px;
          font-weight: 400;
          text-transform: capitalize;

        }

        ul {
          padding: 0 5px 0 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #003156;
          font-family: 'Zen Antique Soft', serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          text-transform: lowercase;


          li {

          }
        }
      }


      .three {

        h5 {
          position: relative;
          width: 297px;
          font-size: 24px;
          margin: 13px 0 0 110px;
        }


      }

      .big_img {
        width: 942px;
        height: 488px;
        border-radius: 5px;
        object-fit: cover;
        transition: 1s;
        &:hover {
          transform: scale(1.03);
        }
      }
    }


    .room__button {
      margin-top: 20px;
      justify-content: center;
      align-items: flex-end;
      display: flex;
    }
  }

  .MoveIn {

    margin: 50px 0 0 ;
    .room {
      max-width: 1540px;
      padding: 0 50px;
      margin: 0 auto 40px;

      div {
        width: 100%;
        height: auto;
        border: 1px solid #003156;
        border-radius: 5px;
        transition: 1s;


        &:hover {
          transform: scale(1.03);
        }

        span {


          background-color: #FFFFFF;
          margin-top: -34px;
          margin-left: 100px;
          position: absolute;
          display: inline-flex;
          width: 75px;
          height: 70px;
          border: 1px solid #003156;
          border-radius: 50%;
          justify-content: center;
          align-items: center;

        }

        h5 {
          font-family: "Zen Dots", cursive;
          color: #003156;
          justify-content: center;
          display: flex;
          position: relative;
          width: 100%;
          font-size: 32px;
          margin: 0;
          padding: 0;
        }

        ul {
          margin: 0;
          padding: 10px 5px 10px 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #003156;
          font-family: 'Zen Antique Soft', serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          text-transform: lowercase;
        }


      }

    }

    .three {
      div {
        height: 120px;

      }

    }

    .room__button {
      justify-content: center;
      display: flex;
    }

  }

  .DeepC {
    justify-content: center;
    display: flex;
    padding: 0 50px;

    .text {
      display: flex;
      margin: 10px 0 5px;

      span {
        background-color: #FFFFFF;
        margin-left: 100px;
        display: inline-flex;
        width: 100px;
        height: 100px;
        border: 1px solid #003156;
        border-radius: 50%;
        justify-content: center;
        align-items: center;

      }

      p {
        margin: 0 0 0 10px;
        align-items: center;
        display: flex;
        padding: 0;
        width: 1000px;
        height: 100px;
        color: #003156;
        text-align: justify;
        font-family: 'Zen Antique Soft', serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

    }

    .room {
      width: 100%;
      height: 420px;
      border: 1px solid #003156;
      border-radius: 5px;
      display: flex;

      .text {
        display: flex;

        ul {
          width: 70%;
          margin: 0;
          padding: 0 5px 10px 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #003156;
          font-family: 'Zen Antique Soft', serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          text-transform: lowercase;


        }

        div {
          align-items: center;
          display: grid;

          img {
            border-radius: 5px;
            width: 350px;
            height: 180px;
            margin: 10px;
            transition: 1s;
            &:hover {
              transform: scale(1.03);
            }
          }
        }

      }

    }

    .room__button {
      margin-top: 20px;
      justify-content: center;
      display: flex;
    }

  }

  .Pets {
    justify-content: center;
    display: flex;
    padding: 0 50px;

    .text {
      display: flex;
      margin: 10px 0 5px;

      span {
        background-color: #FFFFFF;
        margin-left: 100px;
        display: inline-flex;
        width: 100px;
        height: 100px;
        border: 1px solid #003156;
        border-radius: 50%;
        justify-content: center;
        align-items: center;

      }


      p {
        margin: 0 0 0 10px;
        align-items: center;
        display: flex;
        padding: 0;
        width: 1000px;
        height: 100px;
        color: #003156;
        text-align: justify;
        font-family: 'Zen Antique Soft', serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
      }

    }

    .room {
      position: relative;
      margin: 50px 0 0 ;
      max-width: 1440px;
      height: auto;
      border: 1px solid #003156;
      border-radius: 5px;

      span {
        background-color: #FFFFFF;
        top: -40px;
        left: 90px;
        position: absolute;
        display: inline-flex;
        width: 100px;
        height: 100px;
        border: 1px solid #003156;
        border-radius: 50%;
        justify-content: center;
        align-items: center;

      }

      h5 {
        margin: 13px 0 0 128px;
        color: #003156;
        text-align: center;
        font-family: 'Zen Dots', cursive;
        font-size: 32px;
        font-weight: 400;
        text-transform: capitalize;

      }

      .text {
        display: flex;

        ul {
          width: 70%;
          margin: 0;
          padding: 0 5px 10px 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #003156;
          font-family: 'Zen Antique Soft', serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          text-transform: lowercase;

          p {
            width: 95%;
            color: #003156;
            text-transform: lowercase;
          }
          li{
            margin-left: 25px;
          }

          .cat {
            object-fit: cover;
            margin-top: -40px;
            border-radius: 5px;
            float: right;
            width: 312px;
            height: 150px;
            transition: 1s;
            &:hover {
              transform: scale(1.03);
            }
          }

        }


        div {
          align-items: center;
          display: grid;
          width: 25%;

          img {
            object-fit: cover;
            border-radius: 5px;
            width: 100%;
            height: 180px;
            margin: 10px;
            transition: 1s;
            &:hover {
              transform: scale(1.03);
            }
          }
        }

      }

    }

    .room__button {
      margin-top: 20px;
      justify-content: center;
      display: flex;
    }

  }
  .Holiday {
    padding: 0 50px;
    justify-content:center;
    .text {
      display: flex;
      margin: 10px 0 5px;

      span {
        background-color: #FFFFFF;
        margin-left: 100px;
        display: inline-flex;
        width: 100px;
        height: 100px;
        border: 1px solid #003156;
        border-radius: 50%;
        justify-content: center;
        align-items: center;

      }


      p {
        margin: 0 0 0 10px;
        align-items: center;
        display: flex;
        padding: 0;
        width: 1000px;
        height: 100px;
        color: #003156;
        text-align: justify;
        font-family: 'Zen Antique Soft', serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
      }

    }

    .room {
      margin: 50px 0 0 ;
      max-width: 1440px;
      height: auto;
      border: 1px solid #003156;
      color: #003156;
      border-radius: 5px;
      position: relative;

      span {
        background-color: #FFFFFF;
        margin-top: -100px;
        margin-left: 140px;
        position: absolute;
        display: inline-flex;
        width: 100px;
        height: 100px;
        border: 1px solid #003156;
        border-radius: 50%;
        justify-content: center;
        align-items: center;

      }

      h5 {
        margin: 13px 0 0 128px;
        color: #003156;
        text-align: center;
        font-family: 'Zen Dots', cursive;
        font-size: 32px;
        font-weight: 400;
        text-transform: capitalize;

      }

      .text {
        display: flex;

        ul {
          margin-top: 50px;
          width: 60%;
          padding: 0 5px 10px 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: 'Zen Antique Soft', serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          text-transform: lowercase;
          li{
            margin-left: 25px;
          }

        }


        div {
          align-items: center;
          display: grid;

          img {
            border-radius: 5px;
            width: 90%;
            height: auto;
            margin: 10px;
            transition: 1s;
            &:hover {
              transform: scale(1.03);
            }
          }
        }

      }

    }

    .room__button {
      margin-top: 20px;
      justify-content: center;
      display: flex;
    }

  }

  @media only screen and (min-width: 600px) and (max-width: 1439px) {
    .logo {
      display: flex;

      margin: 0 30px;
      padding: 0;

      .logo__img {
        width: 200px;
        height: 62px;
        margin: 30px 0 0;

        background-color: #003156;
      }

      span {

        width: 100%;
        height: 62px;
        margin: 30px 0 0 60px;


        h4 {
          margin-left: 0;
          width: 100%;
          font-size: 24px;
        }

        .type_img {
          margin: -25px 0 0 -30px;
          width: 70px;
          height: 70px;
          border-radius: 70px;

        }
      }
    }
    .MoveIn {
      margin: 30px 0;

      .room {
        justify-content: center;
        padding: 0 30px;
        display: flex;
        margin-bottom: 30px;

        div {

          span {
            margin-top: -25px;
            margin-left: 70px;
            width: 55px;
            height: 50px;

          }

          h5 {
            font-size: 28px;
          }

          ul {
            padding: 10px 5px 10px 25px;
            font-size: 16px;
          }


        }

      }

      .three {
        div {
          height: auto;
          margin-top: -20px;
          margin-bottom: -20px;
        }

      }


    }
    .rooms {

      padding: 0 30px;
      margin: 30px 0;

      .room {
        display: flex;
        margin-bottom: 30px;

        div {
          width: 95%;
          height: auto;


          span {
            margin-top: -25px;
            margin-left: 15px;
            width: 55px;
            height: 55px;

          }

          h5 {
            z-index: 100;
            margin: 8px 0 0 60px;
            font-size: 28px;

          }

          ul {
            margin: 0;
            width: 80%;
            padding: 10px 5px 10px 25px;
            font-size: 16px;


            li {

            }
          }
        }


        .three {

          h5 {
            position: relative;
            width: 80%;
            font-size: 18px;
            margin: 8px 0 0 50px;
          }


        }

        .big_img {
          width: 95%;
          height: auto;
          border-radius: 5px;
        }
      }


      .room__button {
        margin-top: -20px;
        justify-content: center;
        display: flex;
      }
    }
    .DeepC {
      padding: 0 30px;
      margin: 0;

      .text {


        span {
          background-color: #FFFFFF;
          margin-left: 0;
          width: 75px;
          height: 65px;

        }

        p {
          margin: 0 0 0 10px;
          font-size: 18px;
          width: 100%;
          height: auto;
        }

      }

      .room {
        width: 95%;
        height: auto;

        .text {
          display: flex;

          ul {
            width: 70%;
            font-size: 16px;
          }

          div {
            width: 30%;
            align-items: center;
            display: grid;
            justify-content: center;
            margin: 0 auto;

            img {
              border-radius: 5px;
              width: 90%;
              height: 90%;
              margin: 10px;
              object-fit: cover;
            }
          }

        }

      }

      .room__button {
        margin-top: 20px;
        justify-content: center;
        display: flex;
      }

    }
    .Pets {
      padding: 0 30px;

      .room {
        height: auto;

        span {
          background-color: #FFFFFF;
          top: -30px;
          left: 70px;
          width: 70px;
          height: 70px;

        }

        h5 {
          font-size: 28px;

        }

        .text {
          display: flex;

          ul {

            font-size: 16px;
            margin: 0 ;
            padding: 0 10px;

            p {
              font-size: 16px;
              width: 95%;
              height: auto;

            }

            .cat {
              margin-top: 0;

            }

          }


          div {


            img {
              margin: 5px;
            }
          }

        }

      }

      .room__button {
        margin-top: 20px;
        justify-content: center;
        display: flex;
      }

    }
    .Holiday {
      padding: 0 30px;

      .room {
        width: 95%;
        height: auto;

        span {
          position: absolute;
          background-color: #FFFFFF;
          top: 70px;
          left: -80px;
          width: 70px;
          height: 70px;

        }

        h5 {
          font-size: 28px;

        }

        .text {
          display: flex;

          ul {
            min-width: 350px;
            font-size: 16px;
            padding: 0 10px 0;
            p {
              width: 95%;
              height: auto;

            }


          }


          div {
            width: 60%;


            img {
              margin: 0 auto;
            }
          }

        }

      }

      .room__button {
        margin-top: 20px;
        justify-content: center;
        display: flex;
      }

    }

  }
  @media only screen and (max-width: 599px) {
    .logo {
      padding: 0;
      margin: 0 15px;

      .logo__img {
        width: 150px;
        height: 47px;
        margin: 15px 0 0;
      }

      span {
        height: 47px;
        margin: 15px 0 0 30px;


        h4 {
          position: relative;
          margin-left: 10px;
          width: 100%;
          font-size: 16px;
        }

        .type_img {
          margin: -10px 0 0 -20px;
          width: 40px;
          height: 40px;
          border-radius: 70px;

        }
      }
    }
    .MoveIn {
      margin: 25px 0;

      .room {
        padding: 0 15px;

        margin-bottom: 25px;

        div {
          width: 100%;
          height: auto;
          min-height: 250px;

          span {
            margin-top: -20px;
            margin-left: 30px;
            width: 40px;
            height: 40px;

          }

          h5 {
            margin: 8px 0 0 30px;
            font-size: 20px;
          }

          ul {
            padding: 10px 5px 10px 25px;
            font-size: 14px;
          }


        }

      }

      .room__button {
        margin-top: 10px;
      }

      .three {
        div {
          height: 281px;
          margin-top: -10px;
          margin-bottom: -20px;
        }

      }


    }
    .rooms {
      padding: 0 15px;
      margin: 25px 0 0;

      .room {
        justify-content: center;
        margin-bottom: 25px;

        div {
          width: 100%;
          height: auto;
          min-height: 250px;


          span {
            margin-top: -20px;
            margin-left: 30px;
            width: 40px;
            height: 40px;

          }

          h5 {
            z-index: 100;
            margin: 8px 0 0 30px;
            font-size: 20px;

          }

          ul {
            margin: 0;
            width: 80%;
            padding: 10px 5px 10px 25px;
            font-size: 14px;


            li {

            }
          }
        }


        .three {

          h5 {
            width: 80%;
            font-size: 18px;
            margin: 8px 0 0 40px;
          }


        }

        .big_img {
          width: 97%;
          height: auto;
          min-height: 550px;
          border-radius: 5px;
        }
      }


      .room__button {
        margin-top: -10px;
        justify-content: center;
        display: flex;
      }
    }
    .DeepC {
      padding: 0 15px;
      margin: 15px 0;

      .text {
        margin: 0 0 5px;

        span {
          background-color: #FFFFFF;
          margin-left: 0;
          width: 50px;
          height: 50px;

        }

        p {
          text-align: inherit;
          margin: 0 0 0 5px;
          font-size: 18px;
          width: 100%;
          height: auto;
        }

      }

      .room {
        height: auto;
        margin: 0;

        .text {

          display: block;

          ul {
            margin-top: 10px;
            width: 90%;
            font-size: 14px;
          }

          div {
            width: 90%;
            height: auto;
            align-items: center;
            display: flex;
            justify-content: center;
            margin: 0 auto;

            img {
              border-radius: 5px;
              width: 50%;
              height: 90%;
              margin: 10px;
              object-fit: cover;
            }
          }

        }

      }

      .room__button {
        margin-top: 20px;
        justify-content: center;
        display: flex;
      }

    }
    .Pets {
      padding: 20px 15px 0;

      .room {
        margin: 15px auto;
        width: 100%;
        height: auto;

        span {
          background-color: #FFFFFF;
          height: 50px;
          width: 50px;
          top: -20px;
          left: 10px;

        }

        h5 {
          margin: 8px 0 0 45px;
          font-size: 16px;

        }

        .text {
          display: block;

          ul {
            margin: 0;
            padding: 0 15px;
            width: 100%;
            font-size: 14px;

            p {
              font-size: 14px;
              width: auto;
              height: auto;

            }

            .cat {
              object-fit: cover;
              object-position: bottom 60px;
              margin: 10px auto;
              float: top;
              width: 100%;

            }

          }


          div {
            width: 90%;
            align-items: center;
            display: flex;
            justify-content: center;
            margin: 0 auto;


            img {
              border-radius: 5px;
              width: 50%;
              height: 90%;
              margin: 10px;
              object-fit: cover;
            }
          }

        }

      }

      .room__button {

      }

    }
    .Holiday {
      padding: 0 15px;

      .room {
        width: 100%;
        height: auto;
        padding: 15px 5px;

        span {
          position: absolute;
          background-color: #FFFFFF;
          top: 75px;
          left: -130px;
          width: 50px;
          height: 50px;

        }

        h5 {
          margin: 8px 0 0 45px;
          font-size: 16px;

        }

        .text {
          display: block;

          ul {
            margin: 10px 0 ;
            padding: 0 15px;
            width: 100%;
            font-size: 14px;
          }


          div {
            margin: 0 auto;
            width: 95%;

            img {
              margin: 0 auto;
            }
          }

        }

      }

      .room__button {
        margin-top: 20px;
        justify-content: center;
        display: flex;
      }

    }

  }


}




