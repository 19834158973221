
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    background: #fff;
    /* Center slide text vertically */
    display: flex;
    justify-content: flex-start;
}

.swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
}

.mySwiper2 {
    position: relative;
    height: auto;
    width: 100%;
}

.mySwiper {
    max-width: 1440px;
    width: 100%;
    height: 130px;
    padding: 10px 0;
}

.mySwiper .swiper-slide {
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 120px;
    width: 25%;
    opacity: 0.2;
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
}


.group {
    object-fit: cover;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    padding: 0;
    /*padding: 10px 0 0 10px;*/
    width: 350px;
    height: 100px;


}
.mySwiper .Regular{
    background-color: #EFE5D9;
}
.mySwiper .OneTime{
    background-color: #E5E2D6;
}
.mySwiper .Move_in{
    background-color: #EDECE9;
}
.mySwiper .Office{
    background-color: #F6F8FA;
}
.mySwiper .After_Repair{
    background-color: rgba(192, 211, 222);
}
.mySwiper .Deep{
    background-color: #D7EAF4;
}
.mySwiper .Holiday{
    background: #C0D3DE30;

}
.mySwiper .Pets{
    background-color: #D7EAF4;
}
h4{
    justify-content: center;
    display: flex;
    margin: 10px 0;
    color: #003156;
    font-family: 'Zen Antique', serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}
p{
    justify-content: center;
    display: flex;
    margin: 0;
    color: #003156;
    font-family: 'Zen Antique', serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;


}
.Discounto{

    font-size: 12px;
    text-decoration: line-through;

}

.swiper-button-next:after, .swiper-button-prev:after{
    justify-content: end;
    font-size: 50px;

}
.swiper-button-next{
    height: 500px;
    width: 50px;
    top:150px;
    right: 5px;
    align-items: center;
    justify-content: end;
}
.swiper-button-prev{
    height: 500px;
    width: 50px;
    top:150px;
    left: 5px;
    align-items: center;
    justify-content: start;
}

@media only screen and (min-width: 600px) and (max-width: 1439px){

    .group {
        border-radius: 5px;
        padding: 5px 0 0 5px;
        width: 250px;
        height: 80px;
        align-items: center;
    }
    h4{
        margin: 0;
        font-size: 18px;
    }

    p{
        font-size: 14px;
        font-weight: 500;
    }
    .Discounto{

        font-size: 10px;

    }

}
@media only screen and  (max-width: 599px) {
    .group {
        border-radius: 5px;
        padding: 3px;
        width: 170px;
        height: auto;
        align-items: center;

    }
    .group h4{
        margin: 5px 0 5px;
        font-size: 12px;

    }


    p{
        font-size: 10px;
        font-weight: 500;
    }
    .Discounto{

        font-size: 8px;

    }
    .swiper-button-next:after, .swiper-button-prev:after{
        justify-content: end;
        font-size: 20px;
        max-width: 20px;
        height: 20px;

    }
    .swiper-button-next{
        height: 700px;
        width: 30px;
        top:150px;
        right: 5px;
        align-items: center;
        justify-content: end;
    }
    .swiper-button-prev{
        height: 700px;
        width: 30px;
        top:150px;
        left: 5px;
        align-items: center;
        justify-content: start;
    }
    .mySwiper {
        padding: 0 5px;
    }

}


