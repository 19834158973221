.Offering {
  margin-bottom: 50px;
}
.Offering .background {
  position: absolute;
  width: 100%;
  height: 103px;
  background: linear-gradient(180deg, rgba(43, 95, 137, 0.66) 0%, rgba(15, 133, 226, 0) 100%);
}
.Offering .logo {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  padding: 25px 50px;
}
.Offering .logo h3 {
  margin: 37px auto;
  align-items: center;
  color: var(--Primery-Color-2, #003156);
  text-align: center;
  font-family: "Zen Antique", serif;
  font-size: 32px;
  font-weight: 400;
  text-transform: capitalize;
}
.Offering .logo img {
  position: absolute;
  width: 425px;
  height: auto;
  background-color: #003156;
}
.Offering .Offering__groups {
  z-index: 100;
  max-width: 1440px;
  justify-content: center;
  align-items: center;
  display: grid;
  width: 380px;
  transition: 1s;
}
.Offering .Offering__groups img {
  object-fit: cover;
  margin: 0 auto;
  position: relative;
  z-index: 100;
  border-radius: 333px;
  width: 300px;
  height: 300px;
  transform: rotate(-0.13deg);
}
.Offering .Offering__groups:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.Offering .group {
  box-sizing: border-box;
  z-index: -100;
  margin-top: -27px;
  padding: 0;
  border-radius: 5px;
  width: 330px;
  height: 120px;
  align-items: center;
  display: flex;
}
.Offering .group h4 {
  margin: 0;
  color: #003156;
  font-family: "Zen Antique", serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.Offering .group p {
  margin: 0;
  color: #003156;
  font-family: "Zen Antique", serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Offering .group p span {
  font-size: 18px;
  text-decoration: line-through;
}
.Offering .Deep img {
  border: 1px solid #E7C657;
}
.Offering .Deep .group {
  background-color: #D7EAF4;
}
.Offering .After_Repair img {
  border: 1px solid #3C87C0;
}
.Offering .After_Repair .group {
  background-color: rgb(192, 211, 222);
}
.Offering .Office img {
  border: 1px solid #F4D56E;
}
.Offering .Office .group {
  background-color: #F6F8FA;
}
.Offering .Move_in img {
  border: 1px solid #E8BB28;
}
.Offering .Move_in .group {
  background-color: #EDECE9;
}
.Offering .Move_in .group img {
  border: 1px solid #003156;
}
.Offering .OneTime img {
  border: 1px solid #003156;
}
.Offering .OneTime .group {
  background-color: #E5E2D6;
}
.Offering .Regular img {
  border: 1px solid #EDD070;
}
.Offering .Regular .group {
  background-color: #EFE5D9;
}
.Offering .Holiday img {
  border: 1px solid #3C87C0;
}
.Offering .Holiday .group {
  background: rgba(192, 211, 222, 0.1882352941);
}
.Offering .Pets img {
  border: 1px solid #E7C657;
}
.Offering .Pets .group {
  background-color: #D7EAF4;
}
.Offering .container {
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}
.Offering .container .content {
  z-index: 10;
  margin-top: 42px;
  justify-content: center;
  display: flex;
}
.Offering .container .content a {
  text-decoration: none;
}
.Offering .container .ellipse {
  position: absolute;
  width: 200px;
  height: 326px;
  border-radius: 349px;
  background: rgba(17, 149, 255, 0.5);
  filter: blur(150px);
}
.Offering .container .ellipse__one {
  z-index: -1;
  top: -100;
  right: 0;
}
.Offering .container .ellipse__two {
  z-index: -1;
  bottom: 300px;
  left: -100px;
}
.Offering .container .ellipse__three {
  z-index: -1;
  top: 150px;
  left: 700px;
}
@media only screen and (min-width: 600px) and (max-width: 1439px) {
  .Offering .logo {
    padding: 30px 0;
  }
  .Offering .logo h3 {
    margin: 0 auto;
    font-size: 24px;
    font-weight: 400;
  }
  .Offering .logo img {
    z-index: 10;
    position: revert;
    margin-left: 30px;
    width: 200px;
    height: auto;
  }
  .Offering .Offering__groups {
    width: 270px;
    justify-content: center;
  }
  .Offering .Offering__groups img {
    z-index: 100;
    border-radius: 250px;
    width: 250px;
    height: 250px;
  }
  .Offering .group {
    justify-content: center;
    box-sizing: border-box;
    z-index: -100;
    margin-top: -27px;
    border-radius: 5px;
    padding: 27px 8px;
    width: 100%;
    height: 90px;
  }
  .Offering .group h4 {
    font-size: 16px;
  }
  .Offering .group p {
    font-size: 24px;
  }
  .Offering .group p span {
    font-size: 12px;
  }
  .Offering .container {
    justify-content: center;
  }
  .Offering .container .content {
    width: 300px;
    margin-top: 42px;
    justify-content: center;
    display: flex;
  }
  .Offering .container .ellipse__three {
    z-index: -1;
    top: 900px;
    left: 300px;
  }
}
@media only screen and (max-width: 599px) {
  .Offering .logo {
    padding: 15px 0 0;
  }
  .Offering .logo h3 {
    align-items: center;
    display: flex;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 400;
  }
  .Offering .logo img {
    z-index: 10;
    position: revert;
    margin-left: 15px;
    width: 150px;
    height: auto;
  }
  .Offering .Offering__groups {
    width: 170px;
    justify-content: center;
  }
  .Offering .Offering__groups img {
    z-index: 100;
    border-radius: 250px;
    width: 150px;
    height: 150px;
  }
  .Offering .group {
    justify-content: center;
    box-sizing: border-box;
    z-index: -100;
    margin-top: -27px;
    border-radius: 5px;
    padding: 27px 4px;
    width: 100%;
    height: 110px;
  }
  .Offering .group h4 {
    font-size: 18px;
    padding: 5px;
  }
  .Offering .group p {
    font-size: 14px;
    white-space: nowrap;
  }
  .Offering .group p span {
    font-size: 10px;
  }
  .Offering .container .content {
    margin-top: 20px;
  }
  .Offering .container .ellipse__three {
    z-index: -1;
    top: 700px;
    left: 100px;
  }
}

