.form {
  margin-top: -50px !important;
  margin-bottom: 50px;
  border: #003156 1px solid;
  border-radius: 50px;
  max-width: 1240px;
  padding: 20px 0 30px;
}
.form .Button__Booking {
  width: 100%;
  height: 56px;
  box-shadow: none;
  box-sizing: border-box;
}
.form .logo {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  padding: 0 50px;
}
.form .logo h3 {
  width: 100%;
  z-index: 100;
  margin: 0 auto 20px;
  align-items: flex-start;
  color: var(--Primery-Color-2, #003156);
  text-align: center;
  font-family: "Zen Antique", serif;
  font-size: 32px;
  font-weight: 600;
  text-transform: capitalize;
}
.form .FormControl {
  padding: 0 20px;
}
.form .progress {
  margin: 0 40px 0;
  display: flex;
  gap: 10px;
}
.form .progress .step {
  width: 25%;
}
.form .progress .step h5 {
  text-align: left;
  margin: 0;
  padding: 0 0 6px 0;
  color: #003156;
  font-family: "Zen Antique", serif;
  font-weight: 600;
  font-size: 22px;
}
.form .progress .step .span {
  width: 100%;
  height: 15px;
  background-color: #003156;
}
.form .select {
  margin: 0 40px 0;
}
.form .select h6 {
  text-align: left;
  margin: 20px auto 30px;
  padding: 0 0 0 0;
  color: #BCBCBC;
  font-family: "Zen Antique", serif;
  font-weight: 900;
  font-size: 20px;
}
.form .select .pay {
  text-align: center;
  color: #003156;
  font-size: 24px;
}
.form .select .application {
  color: #003156;
  font-size: 22px;
}
.form .line {
  margin: 30px auto 0;
  height: 1px;
  width: 93%;
  background-color: #BCBCBC;
}
.form .applicationBox {
  margin: 30px 40px 0;
}
.form .applicationBox .application {
  display: flex;
  align-items: flex-end;
}
.form .applicationBox .application h6 {
  text-align: left;
  padding: 0 0 0 0;
  margin: 0;
  color: #003156;
  font-family: "Zen Antique", serif;
  font-weight: 900;
  font-size: 22px;
}
.form .applicationBox .application p {
  text-align: left;
  margin: 0;
  padding: 0 0 0 0;
  color: #003156;
  opacity: 0.8;
  font-family: "Zen Antique", serif;
  font-weight: 900;
  font-size: 20px;
}
.form .applicationBox .formValid h6 {
  text-align: left;
  padding: 0 0 0 0;
  margin: 0;
  color: red;
  font-family: "Zen Antique", serif;
  font-weight: 900;
  font-size: 18px;
}
.form .button_Box {
  margin: 0;
  padding: 30px 40px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.form .form_name {
  margin: 0;
  padding: 0 0 16px 8px;
  color: #003156;
  font-family: "Zen Antique", serif;
  font-weight: 600;
  font-size: 24px;
}
.form .Error {
  color: red;
}
.form .button {
  margin-top: 50px;
  justify-content: center;
  text-transform: initial;
  position: relative;
  display: inline-block;
}
.form .button:disabled {
  background-color: #003156;
  opacity: 0.5;
  color: #fff;
  cursor: not-allowed;
  pointer-events: auto;
}
.form .width60ch {
  width: 60ch;
}
.form .width30ch {
  width: 100%;
}
.form .ellipse {
  position: absolute;
  width: 200px;
  height: 326px;
  border-radius: 349px;
  background: rgba(17, 149, 255, 0.5);
  filter: blur(150px);
}
.form .ellipse__two {
  z-index: -1;
  bottom: 300px;
  left: -100px;
}
.form .ellipse__three {
  z-index: -1;
  top: 100px;
  right: 0;
}
@media only screen and (min-width: 600px) and (max-width: 1439px) {
  .form {
    margin: 50px 30px !important;
    max-width: 1400px;
  }
  .form .progress .step h5 {
    display: none;
  }
  .form .logo {
    padding: 40px 0 0;
  }
  .form .logo h3 {
    margin: 10px auto 30px;
    font-size: 28px;
    font-weight: 400;
  }
}
@media only screen and (max-width: 599px) {
  .form {
    margin: -20px 20px 20px !important;
  }
  .form .FormControl {
    padding: 0 0;
  }
  .form .progress .step h5 {
    display: none;
  }
  .form .select {
    margin: 0 20px 0;
  }
  .form .select h6 {
    margin: 10px auto 15px;
    font-size: 16px;
  }
  .form .select .pay {
    font-size: 16px;
  }
  .form .select .application {
    font-size: 16px;
  }
  .form .applicationBox {
    margin: 30px 20px 0;
  }
  .form .applicationBox .service {
    display: block;
  }
  .form .applicationBox .service p {
    justify-content: flex-start;
  }
  .form .applicationBox .squareFootage {
    display: block;
  }
  .form .applicationBox .squareFootage p {
    justify-content: flex-start;
  }
  .form .ellipse {
    width: 100px;
    height: 150px;
  }
  .form .ellipse__two {
    z-index: -1;
    bottom: 100px;
    left: -100px;
  }
  .form .ellipse__three {
    z-index: -1;
    top: 100px;
    right: 0;
  }
  .form .logo {
    padding: 15px 0 0;
  }
  .form .logo h3 {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto 30px;
    font-size: 20px;
    font-weight: 300;
  }
  .form div h3 {
    font-family: "Zen Antique", serif;
    font-size: 24px;
    text-align: center;
    width: 300px;
    margin: 15px auto 15px;
  }
  .form .width60ch {
    width: 100%;
  }
  .form .width30ch {
    width: 100%;
  }
}

