
a{
}

.hide {
  display: flex;

  .hide__button{
    align-items: center;
    margin-right: 32px;
    display: block;
    padding: 10px;

  }

}

.name-logo{
  margin: 0 ;
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  font-family: 'Work Sans', sans-serif;

}

.nav-wrapper {
  padding: 0 50px;
  max-width: 1440px;
  margin: 34px auto 0;

  .MenuIcon{
    z-index: 9999;
    right: 40px;
    top:30px;
    background-color: #fff;
    width: 50px;
    height: 50px;
    border: #003156 1px solid;
    align-items: center;
  }


  a {
    display: contents;
    padding: 0  ;
    text-decoration: none;
    color: #003156;
    font-style: normal;
    line-height: normal;

    img{
      width: 425px;
      height: auto;
      background-color: #003156;

    }
    .MuiButtonBase-root{
      font-family: 'Zen Antique', serif;

    }
  }

  h3{
    justify-content: center;
    display: flex;
    max-width: 425px;
    color:  #003156;
    font-family: 'Work Sans',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media only screen and (min-width: 600px) and (max-width: 1439px) {

    .MenuIcon{
      background-color: #fff;

      right: 10px;
      top:0;
      width: 50px;
      height: 50px;
    }

    .hide {
      width: 100%;
      justify-content: space-between;

      .hide__button{
        margin-right: 5px;
        display: block;
        padding: 5px;

      }

    }

    margin: 20px 30px 0;
    padding: 0 ;

    a{
      padding: 0;
      img{
        width: 200px;
        height: auto;
      }
    }
    h3{
      max-width: 200px;
      font-size: 10px;
    }
  }
  @media only screen and  (max-width: 599px) {
    margin: 10px auto 0;
    padding: 0 15px;

    .MenuIcon{
      background-color: #fff;

      right: 8px;
      top:0;
      width: 45px;
      height: 45px;
    }

    a{
      justify-content: center;

      img{
        width: 300px;
        height: auto;
      }
    }
    h3{
      margin: -5px 0 50px;
      justify-content: center;
      max-width: 300px;
      font-size: 6px;
    }

  }
}
