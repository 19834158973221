.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  font-size: 18px;
  background: rgba(17, 255, 238, 0) !important;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: auto;
  height: auto;
  object-fit: cover;
}

.swiper-pagination-bullet-active {
  background-color: #003156;
}

