.Customers {




  .background {
    position: relative;
    width: 100%;
    height: 103px;
    background: linear-gradient(180deg, rgba(43, 95, 137, 0.66) 0%, rgba(15, 133, 226, 0.00) 100%);

    h3 {
      color: #003156;
      text-align: center;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.8px;
      text-transform: uppercase;
      font-family: 'Zen Antique', serif;
      font-size: 32px;
      font-weight: 400;
    }


  }

  .comments {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    max-width: 1440px;


    .steamy{
      background: #B5D5ED !important;
      h4{color: #FFFFFF !important;}
      h5{color: #FFFFFF !important;}
      p{color: #FFFFFF !important;}
      color: #FFFFFF !important;

    }

    .comment {
      width: 90%;
      height: auto;
      border-radius: 5px;
      border: 1px solid #B5D5ED;
      background: #FFF;
      justify-content: center;

      h4 {
        width: 170px;
        margin: 50px auto 0;
        color: #003156;
        text-align: center;
        font-family: 'Work Sans', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 100% */
        text-transform: capitalize;
      }

      h5 {
        width: 200px;
        margin: 7px auto 0;
        color: #003156;
        text-align: center;
        font-family: 'Work Sans', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        text-transform: capitalize;
      }

      p {
        margin: 0 auto;
        padding: 28px 28px 0  ;
        color: #003156;
        text-align: center;
        font-family: 'Work Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        text-transform: capitalize;
        overflow: hidden;

      }
      .Button {
        background-color: #11ffee00;
        padding: 0 0 10px 0;
        border: none;
        display: flex;
        width: 100%;
        height: 28px;
        align-items: center;
        justify-content: center;


        .More__p {
          margin: 0 10px 0 0;
          padding: 0;
          color: #003156;
          font-family: 'Work Sans', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        span{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 28px;
          width: 28px;
        }
        &:hover{
          cursor: pointer;
        }
      }
      .More{
        color: #F35D5D !important;
        .More__p{color: #F35D5D !important;}

      }
      .Swap{
        color: #F35D5D !important;
        transform: rotate(180deg) ;
        transition: 0.5s;
      }
      .show{
        height: 210px;
      }
    }

  }


  .MuiGrid-root{
    justify-content: center;
    display: flex;
    margin-bottom: 24px;
  }

  @media only screen and (min-width: 600px) and  (max-width: 1440px) {
    .comments{
      .comment{



        p{
        }

      }
    }

  }
  @media only screen and (max-width: 599px) {
    .comments{
      .comment{



        p {
          font-size: 12px;
          padding: 10px ;


        }

      }
    }

  }

}

.Modal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: white;
  border: 2px solid #003156;
  padding: 30px;

  .width60ch{
    width: 60ch;
  }
  .Error{
    color: red !important;
  }
  .form_name{
    margin: 0;
    padding: 0 0 16px 8px;
    color: #003156;
    font-family: 'Zen Antique', serif;
    font-weight: 600;
    font-size: 24px;
  }

  .button{
    margin: 50px auto 0 ;
    justify-content: center;
    display: flex;

    &:disabled{
      background-color: #003156;
      opacity: 0.5;
      color: #fff;
      cursor: not-allowed;
      pointer-events: auto;
    }
  }

  @media only screen and  (max-width: 700px) {
    padding: 20px 0 0;


    .width60ch{
      width: 30ch;
    }

    .button{
      margin: 20px auto 10px ;
      width: 200px;

    }
  }

}
.Button__feedback{
  width: 300px;
  height: 50px;
}
@media only screen and  (max-width: 599px) {
  .Button__feedback{
    width: 150px;
    height: 50px;
  }

}



