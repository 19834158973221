.Offering {
  margin-bottom: 50px;


  .background {
    position: absolute;
    width: 100%;
    height: 103px;
    background: linear-gradient(180deg, rgba(43, 95, 137, 0.66) 0%, rgba(15, 133, 226, 0.00) 100%);


  }

  .logo {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    padding: 25px 50px;

    h3 {
      margin: 62-25px auto;
      align-items: center;
      color: var(--Primery-Color-2, #003156);
      text-align: center;
      font-family: 'Zen Antique', serif;
      font-size: 32px;
      font-weight: 400;
      text-transform: capitalize;
    }

    img {
      position: absolute;
      width: 425px;
      height: auto;
      background-color: #003156;
    }
  }

  .Offering__groups {
    z-index: 100;
    max-width: 1440px;
    justify-content: center;
    align-items: center;
    display: grid;
    width: 380px;
    transition: 1s;

    img {
      object-fit: cover;
      margin: 0 auto;
      position: relative;
      z-index: 100;
      border-radius: 333px;
      width: 300px;
      height: 300px;
      transform: rotate(-0.13deg);


    }

    &:hover {
      transform: scale(1.05);
      cursor: pointer;

    }


  }

  .group {
    box-sizing: border-box;
    z-index: -100;
    margin-top: -27px;
    padding: 0;
    //padding-top: 30px;
    border-radius: 5px;
    width: 330px;
    height: 120px;
    align-items: center;
    display: flex;


    h4 {
      margin: 0;
      color: #003156;
      font-family: 'Zen Antique', serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }

    p {
      margin: 0;
      color: #003156;
      font-family: 'Zen Antique', serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;


      span{

        font-size: 18px;
        text-decoration: line-through;

      }
    }
  }

  .Deep {
    img {
      border: 1px solid #E7C657;
    }

    .group {
      background-color: #D7EAF4;

    }
  }

  .After_Repair {
    img {
      border: 1px solid #3C87C0;
    }

    .group {
      background-color: rgba(192, 211, 222,);


    }
  }

  .Office {
    img {
      border: 1px solid #F4D56E;

    }

    .group {
      background-color: #F6F8FA;

    }
  }

  .Move_in {
    img {
      border: 1px solid #E8BB28;
    }

    .group {
      img {
        border: 1px solid #003156;
      }

      background-color: #EDECE9;

    }
  }

  .OneTime {
    img {
      border: 1px solid #003156;

    }

    .group {
      background-color: #E5E2D6;


    }
  }

  .Regular {
    img {
      border: 1px solid #EDD070;
    }

    .group {
      background-color: #EFE5D9;
    }
  }

  .Holiday {
    img {
      border: 1px solid #3C87C0;
    }

    .group {
      background: #C0D3DE30;


    }
  }

  .Pets {
    img {
      border: 1px solid #E7C657;
    }

    .group {
      background-color: #D7EAF4;

    }
  }


  .container {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;


    .content {
      z-index: 10;
      margin-top: 42px;
      justify-content: center;
      display: flex;
      a{
        text-decoration: none;

      }

    }
    .ellipse {
      position: absolute;
      width: 200px;
      height: 326px;
      border-radius: 349px;
      background: rgba(17, 149, 255, 0.50);
      filter: blur(150px);

    }

    .ellipse__one {
      z-index: -1;
      top: -100;
      right: 0;

    }

    .ellipse__two {
      z-index: -1;
      bottom: 300px;
      left: -100px;
    }
    .ellipse__three{
      z-index: -1;
      top: 150px;
      left: 700px;
    }

  }

  @media only screen and (min-width: 600px) and (max-width: 1439px) {
    .logo {
      padding: 30px 0;


      h3 {
        margin: 0 auto;
        font-size: 24px;
        font-weight: 400;
      }

      img {
        z-index: 10;
        position: revert;
        margin-left: 30px;
        width: 200px;
        height: auto;
      }
    }
    .Offering__groups {
      width: 270px;
      justify-content: center;

      img {
        z-index: 100;
        border-radius: 250px;
        width: 250px;
        height: 250px;

      }
    }
    .group {
      justify-content: center;
      box-sizing: border-box;
      z-index: -100;
      margin-top: -27px;
      border-radius: 5px;
      padding: 27px 8px;
      width: 100%;
      height: 90px;

      h4 {
        font-size: 16px;

      }

      p {
        font-size: 24px;
        span{

          font-size: 12px;

        }

      }
    }
    .container {
      justify-content: center;


      .content {
        width: 300px;
        margin-top: 42px;
        justify-content: center;
        display: flex;

      }
      .ellipse__three{
        z-index: -1;
        top: 900px;
        left: 300px;
      }

    }

  }
  @media only screen and (max-width: 599px) {
    .logo {
      padding: 15px 0 0;


      h3 {
        align-items: center;
        display: flex;
        margin: 0 auto;
        font-size: 16px;
        font-weight: 400;
      }

      img {
        z-index: 10;
        position: revert;
        margin-left: 15px;
        width: 150px;
        height: auto;
      }
    }
    .Offering__groups {
      width: 170px;
      justify-content: center;

      img {
        z-index: 100;
        border-radius: 250px;
        width: 150px;
        height: 150px;

      }
    }
    .group {
      justify-content: center;
      box-sizing: border-box;
      z-index: -100;
      margin-top: -27px;
      border-radius: 5px;
      padding: 27px 4px;
      width: 100%;
      height: 110px;

      h4 {
        font-size: 18px;
        padding: 5px;

      }

      p {
        font-size: 14px;
        white-space: nowrap;
        span{

          font-size: 10px;

        }

      }
    }
    .container {


      .content {
        margin-top: 20px;

      }
      .ellipse__three{
        z-index: -1;
        top: 700px;
        left: 100px;
      }

    }

  }

}
