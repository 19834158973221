.Customers .background {
  position: relative;
  width: 100%;
  height: 103px;
  background: linear-gradient(180deg, rgba(43, 95, 137, 0.66) 0%, rgba(15, 133, 226, 0) 100%);
}
.Customers .background h3 {
  color: #003156;
  text-align: center;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  font-family: "Zen Antique", serif;
  font-size: 32px;
  font-weight: 400;
}
.Customers .comments {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  max-width: 1440px;
}
.Customers .comments .steamy {
  background: #B5D5ED !important;
  color: #FFFFFF !important;
}
.Customers .comments .steamy h4 {
  color: #FFFFFF !important;
}
.Customers .comments .steamy h5 {
  color: #FFFFFF !important;
}
.Customers .comments .steamy p {
  color: #FFFFFF !important;
}
.Customers .comments .comment {
  width: 90%;
  height: auto;
  border-radius: 5px;
  border: 1px solid #B5D5ED;
  background: #FFF;
  justify-content: center;
}
.Customers .comments .comment h4 {
  width: 170px;
  margin: 50px auto 0;
  color: #003156;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 100% */
  text-transform: capitalize;
}
.Customers .comments .comment h5 {
  width: 200px;
  margin: 7px auto 0;
  color: #003156;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  text-transform: capitalize;
}
.Customers .comments .comment p {
  margin: 0 auto;
  padding: 28px 28px 0;
  color: #003156;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-transform: capitalize;
  overflow: hidden;
}
.Customers .comments .comment .Button {
  background-color: rgba(17, 255, 238, 0);
  padding: 0 0 10px 0;
  border: none;
  display: flex;
  width: 100%;
  height: 28px;
  align-items: center;
  justify-content: center;
}
.Customers .comments .comment .Button .More__p {
  margin: 0 10px 0 0;
  padding: 0;
  color: #003156;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Customers .comments .comment .Button span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
}
.Customers .comments .comment .Button:hover {
  cursor: pointer;
}
.Customers .comments .comment .More {
  color: #F35D5D !important;
}
.Customers .comments .comment .More .More__p {
  color: #F35D5D !important;
}
.Customers .comments .comment .Swap {
  color: #F35D5D !important;
  transform: rotate(180deg);
  transition: 0.5s;
}
.Customers .comments .comment .show {
  height: 210px;
}
.Customers .MuiGrid-root {
  justify-content: center;
  display: flex;
  margin-bottom: 24px;
}
@media only screen and (max-width: 599px) {
  .Customers .comments .comment p {
    font-size: 12px;
    padding: 10px;
  }
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: white;
  border: 2px solid #003156;
  padding: 30px;
}
.Modal .width60ch {
  width: 60ch;
}
.Modal .Error {
  color: red !important;
}
.Modal .form_name {
  margin: 0;
  padding: 0 0 16px 8px;
  color: #003156;
  font-family: "Zen Antique", serif;
  font-weight: 600;
  font-size: 24px;
}
.Modal .button {
  margin: 50px auto 0;
  justify-content: center;
  display: flex;
}
.Modal .button:disabled {
  background-color: #003156;
  opacity: 0.5;
  color: #fff;
  cursor: not-allowed;
  pointer-events: auto;
}
@media only screen and (max-width: 700px) {
  .Modal {
    padding: 20px 0 0;
  }
  .Modal .width60ch {
    width: 30ch;
  }
  .Modal .button {
    margin: 20px auto 10px;
    width: 200px;
  }
}

.Button__feedback {
  width: 300px;
  height: 50px;
}

@media only screen and (max-width: 599px) {
  .Button__feedback {
    width: 150px;
    height: 50px;
  }
}

