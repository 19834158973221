.Cleaning__container {
  margin: 0 auto;
  width: 100%;
}
.Cleaning__container .background {
  position: absolute;
  width: 100%;
  height: 103px;
  background: linear-gradient(180deg, rgba(43, 95, 137, 0.66) 0%, rgba(15, 133, 226, 0) 100%);
}
.Cleaning__container .logo {
  max-width: 1440px;
  display: flex;
  padding: 0 50px;
  margin: 0 auto;
}
.Cleaning__container .logo .logo__img {
  z-index: 10;
  width: 420px;
  height: 139px;
  margin: 39px 0 0;
  background-color: #003156;
}
.Cleaning__container .logo span {
  z-index: 10;
  display: inline-flex;
  width: 100%;
  height: 139px;
  margin: 39px 0 0 124px;
  background-color: #003156;
}
.Cleaning__container .logo span h4 {
  display: flex;
  margin-left: 60px;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #EED170;
  text-align: center;
  font-family: "Frank Ruhl Libre", serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.Cleaning__container .logo span .type_img {
  margin: -30px 0 0 -70px;
  position: absolute;
  width: 155px;
  height: 155px;
  transform: rotate(-0.145deg);
  border-radius: 154.632px;
  border: 1px solid #EDD070;
  object-fit: cover;
}
.Cleaning__container .rooms {
  max-width: 1540px;
  padding: 0 50px;
  justify-content: center;
  display: flex;
  margin: 50px auto 0;
}
.Cleaning__container .rooms .left {
  justify-content: flex-start;
}
.Cleaning__container .rooms .center {
  justify-content: center;
}
.Cleaning__container .rooms .right {
  justify-content: right;
}
.Cleaning__container .rooms .room {
  display: flex;
}
.Cleaning__container .rooms .room div {
  width: 430px;
  height: 492px;
  border: 1px solid #003156;
  border-radius: 5px;
  transition: 1s;
}
.Cleaning__container .rooms .room div:hover {
  transform: scale(1.03);
}
.Cleaning__container .rooms .room div span {
  background-color: #FFFFFF;
  margin-top: -40px;
  margin-left: 10px;
  position: absolute;
  display: inline-flex;
  width: 100px;
  height: 100px;
  border: 1px solid #003156;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.Cleaning__container .rooms .room div h5 {
  margin: 13px 0 0 128px;
  color: #003156;
  text-align: center;
  font-family: "Zen Dots", cursive;
  font-size: 32px;
  font-weight: 400;
  text-transform: capitalize;
}
.Cleaning__container .rooms .room div ul {
  padding: 0 5px 0 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #003156;
  font-family: "Zen Antique Soft", serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-transform: lowercase;
}
.Cleaning__container .rooms .room .three h5 {
  position: relative;
  width: 297px;
  font-size: 24px;
  margin: 13px 0 0 110px;
}
.Cleaning__container .rooms .room .big_img {
  width: 942px;
  height: 488px;
  border-radius: 5px;
  object-fit: cover;
  transition: 1s;
}
.Cleaning__container .rooms .room .big_img:hover {
  transform: scale(1.03);
}
.Cleaning__container .rooms .room__button {
  margin-top: 20px;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}
.Cleaning__container .MoveIn {
  margin: 50px 0 0;
}
.Cleaning__container .MoveIn .room {
  max-width: 1540px;
  padding: 0 50px;
  margin: 0 auto 40px;
}
.Cleaning__container .MoveIn .room div {
  width: 100%;
  height: auto;
  border: 1px solid #003156;
  border-radius: 5px;
  transition: 1s;
}
.Cleaning__container .MoveIn .room div:hover {
  transform: scale(1.03);
}
.Cleaning__container .MoveIn .room div span {
  background-color: #FFFFFF;
  margin-top: -34px;
  margin-left: 100px;
  position: absolute;
  display: inline-flex;
  width: 75px;
  height: 70px;
  border: 1px solid #003156;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.Cleaning__container .MoveIn .room div h5 {
  font-family: "Zen Dots", cursive;
  color: #003156;
  justify-content: center;
  display: flex;
  position: relative;
  width: 100%;
  font-size: 32px;
  margin: 0;
  padding: 0;
}
.Cleaning__container .MoveIn .room div ul {
  margin: 0;
  padding: 10px 5px 10px 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #003156;
  font-family: "Zen Antique Soft", serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-transform: lowercase;
}
.Cleaning__container .MoveIn .three div {
  height: 120px;
}
.Cleaning__container .MoveIn .room__button {
  justify-content: center;
  display: flex;
}
.Cleaning__container .DeepC {
  justify-content: center;
  display: flex;
  padding: 0 50px;
}
.Cleaning__container .DeepC .text {
  display: flex;
  margin: 10px 0 5px;
}
.Cleaning__container .DeepC .text span {
  background-color: #FFFFFF;
  margin-left: 100px;
  display: inline-flex;
  width: 100px;
  height: 100px;
  border: 1px solid #003156;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.Cleaning__container .DeepC .text p {
  margin: 0 0 0 10px;
  align-items: center;
  display: flex;
  padding: 0;
  width: 1000px;
  height: 100px;
  color: #003156;
  text-align: justify;
  font-family: "Zen Antique Soft", serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Cleaning__container .DeepC .room {
  width: 100%;
  height: 420px;
  border: 1px solid #003156;
  border-radius: 5px;
  display: flex;
}
.Cleaning__container .DeepC .room .text {
  display: flex;
}
.Cleaning__container .DeepC .room .text ul {
  width: 70%;
  margin: 0;
  padding: 0 5px 10px 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #003156;
  font-family: "Zen Antique Soft", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-transform: lowercase;
}
.Cleaning__container .DeepC .room .text div {
  align-items: center;
  display: grid;
}
.Cleaning__container .DeepC .room .text div img {
  border-radius: 5px;
  width: 350px;
  height: 180px;
  margin: 10px;
  transition: 1s;
}
.Cleaning__container .DeepC .room .text div img:hover {
  transform: scale(1.03);
}
.Cleaning__container .DeepC .room__button {
  margin-top: 20px;
  justify-content: center;
  display: flex;
}
.Cleaning__container .Pets {
  justify-content: center;
  display: flex;
  padding: 0 50px;
}
.Cleaning__container .Pets .text {
  display: flex;
  margin: 10px 0 5px;
}
.Cleaning__container .Pets .text span {
  background-color: #FFFFFF;
  margin-left: 100px;
  display: inline-flex;
  width: 100px;
  height: 100px;
  border: 1px solid #003156;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.Cleaning__container .Pets .text p {
  margin: 0 0 0 10px;
  align-items: center;
  display: flex;
  padding: 0;
  width: 1000px;
  height: 100px;
  color: #003156;
  text-align: justify;
  font-family: "Zen Antique Soft", serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.Cleaning__container .Pets .room {
  position: relative;
  margin: 50px 0 0;
  max-width: 1440px;
  height: auto;
  border: 1px solid #003156;
  border-radius: 5px;
}
.Cleaning__container .Pets .room span {
  background-color: #FFFFFF;
  top: -40px;
  left: 90px;
  position: absolute;
  display: inline-flex;
  width: 100px;
  height: 100px;
  border: 1px solid #003156;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.Cleaning__container .Pets .room h5 {
  margin: 13px 0 0 128px;
  color: #003156;
  text-align: center;
  font-family: "Zen Dots", cursive;
  font-size: 32px;
  font-weight: 400;
  text-transform: capitalize;
}
.Cleaning__container .Pets .room .text {
  display: flex;
}
.Cleaning__container .Pets .room .text ul {
  width: 70%;
  margin: 0;
  padding: 0 5px 10px 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #003156;
  font-family: "Zen Antique Soft", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-transform: lowercase;
}
.Cleaning__container .Pets .room .text ul p {
  width: 95%;
  color: #003156;
  text-transform: lowercase;
}
.Cleaning__container .Pets .room .text ul li {
  margin-left: 25px;
}
.Cleaning__container .Pets .room .text ul .cat {
  object-fit: cover;
  margin-top: -40px;
  border-radius: 5px;
  float: right;
  width: 312px;
  height: 150px;
  transition: 1s;
}
.Cleaning__container .Pets .room .text ul .cat:hover {
  transform: scale(1.03);
}
.Cleaning__container .Pets .room .text div {
  align-items: center;
  display: grid;
  width: 25%;
}
.Cleaning__container .Pets .room .text div img {
  object-fit: cover;
  border-radius: 5px;
  width: 100%;
  height: 180px;
  margin: 10px;
  transition: 1s;
}
.Cleaning__container .Pets .room .text div img:hover {
  transform: scale(1.03);
}
.Cleaning__container .Pets .room__button {
  margin-top: 20px;
  justify-content: center;
  display: flex;
}
.Cleaning__container .Holiday {
  padding: 0 50px;
  justify-content: center;
}
.Cleaning__container .Holiday .text {
  display: flex;
  margin: 10px 0 5px;
}
.Cleaning__container .Holiday .text span {
  background-color: #FFFFFF;
  margin-left: 100px;
  display: inline-flex;
  width: 100px;
  height: 100px;
  border: 1px solid #003156;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.Cleaning__container .Holiday .text p {
  margin: 0 0 0 10px;
  align-items: center;
  display: flex;
  padding: 0;
  width: 1000px;
  height: 100px;
  color: #003156;
  text-align: justify;
  font-family: "Zen Antique Soft", serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.Cleaning__container .Holiday .room {
  margin: 50px 0 0;
  max-width: 1440px;
  height: auto;
  border: 1px solid #003156;
  color: #003156;
  border-radius: 5px;
  position: relative;
}
.Cleaning__container .Holiday .room span {
  background-color: #FFFFFF;
  margin-top: -100px;
  margin-left: 140px;
  position: absolute;
  display: inline-flex;
  width: 100px;
  height: 100px;
  border: 1px solid #003156;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.Cleaning__container .Holiday .room h5 {
  margin: 13px 0 0 128px;
  color: #003156;
  text-align: center;
  font-family: "Zen Dots", cursive;
  font-size: 32px;
  font-weight: 400;
  text-transform: capitalize;
}
.Cleaning__container .Holiday .room .text {
  display: flex;
}
.Cleaning__container .Holiday .room .text ul {
  margin-top: 50px;
  width: 60%;
  padding: 0 5px 10px 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Zen Antique Soft", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-transform: lowercase;
}
.Cleaning__container .Holiday .room .text ul li {
  margin-left: 25px;
}
.Cleaning__container .Holiday .room .text div {
  align-items: center;
  display: grid;
}
.Cleaning__container .Holiday .room .text div img {
  border-radius: 5px;
  width: 90%;
  height: auto;
  margin: 10px;
  transition: 1s;
}
.Cleaning__container .Holiday .room .text div img:hover {
  transform: scale(1.03);
}
.Cleaning__container .Holiday .room__button {
  margin-top: 20px;
  justify-content: center;
  display: flex;
}
@media only screen and (min-width: 600px) and (max-width: 1439px) {
  .Cleaning__container .logo {
    display: flex;
    margin: 0 30px;
    padding: 0;
  }
  .Cleaning__container .logo .logo__img {
    width: 200px;
    height: 62px;
    margin: 30px 0 0;
    background-color: #003156;
  }
  .Cleaning__container .logo span {
    width: 100%;
    height: 62px;
    margin: 30px 0 0 60px;
  }
  .Cleaning__container .logo span h4 {
    margin-left: 0;
    width: 100%;
    font-size: 24px;
  }
  .Cleaning__container .logo span .type_img {
    margin: -25px 0 0 -30px;
    width: 70px;
    height: 70px;
    border-radius: 70px;
  }
  .Cleaning__container .MoveIn {
    margin: 30px 0;
  }
  .Cleaning__container .MoveIn .room {
    justify-content: center;
    padding: 0 30px;
    display: flex;
    margin-bottom: 30px;
  }
  .Cleaning__container .MoveIn .room div span {
    margin-top: -25px;
    margin-left: 70px;
    width: 55px;
    height: 50px;
  }
  .Cleaning__container .MoveIn .room div h5 {
    font-size: 28px;
  }
  .Cleaning__container .MoveIn .room div ul {
    padding: 10px 5px 10px 25px;
    font-size: 16px;
  }
  .Cleaning__container .MoveIn .three div {
    height: auto;
    margin-top: -20px;
    margin-bottom: -20px;
  }
  .Cleaning__container .rooms {
    padding: 0 30px;
    margin: 30px 0;
  }
  .Cleaning__container .rooms .room {
    display: flex;
    margin-bottom: 30px;
  }
  .Cleaning__container .rooms .room div {
    width: 95%;
    height: auto;
  }
  .Cleaning__container .rooms .room div span {
    margin-top: -25px;
    margin-left: 15px;
    width: 55px;
    height: 55px;
  }
  .Cleaning__container .rooms .room div h5 {
    z-index: 100;
    margin: 8px 0 0 60px;
    font-size: 28px;
  }
  .Cleaning__container .rooms .room div ul {
    margin: 0;
    width: 80%;
    padding: 10px 5px 10px 25px;
    font-size: 16px;
  }
  .Cleaning__container .rooms .room .three h5 {
    position: relative;
    width: 80%;
    font-size: 18px;
    margin: 8px 0 0 50px;
  }
  .Cleaning__container .rooms .room .big_img {
    width: 95%;
    height: auto;
    border-radius: 5px;
  }
  .Cleaning__container .rooms .room__button {
    margin-top: -20px;
    justify-content: center;
    display: flex;
  }
  .Cleaning__container .DeepC {
    padding: 0 30px;
    margin: 0;
  }
  .Cleaning__container .DeepC .text span {
    background-color: #FFFFFF;
    margin-left: 0;
    width: 75px;
    height: 65px;
  }
  .Cleaning__container .DeepC .text p {
    margin: 0 0 0 10px;
    font-size: 18px;
    width: 100%;
    height: auto;
  }
  .Cleaning__container .DeepC .room {
    width: 95%;
    height: auto;
  }
  .Cleaning__container .DeepC .room .text {
    display: flex;
  }
  .Cleaning__container .DeepC .room .text ul {
    width: 70%;
    font-size: 16px;
  }
  .Cleaning__container .DeepC .room .text div {
    width: 30%;
    align-items: center;
    display: grid;
    justify-content: center;
    margin: 0 auto;
  }
  .Cleaning__container .DeepC .room .text div img {
    border-radius: 5px;
    width: 90%;
    height: 90%;
    margin: 10px;
    object-fit: cover;
  }
  .Cleaning__container .DeepC .room__button {
    margin-top: 20px;
    justify-content: center;
    display: flex;
  }
  .Cleaning__container .Pets {
    padding: 0 30px;
  }
  .Cleaning__container .Pets .room {
    height: auto;
  }
  .Cleaning__container .Pets .room span {
    background-color: #FFFFFF;
    top: -30px;
    left: 70px;
    width: 70px;
    height: 70px;
  }
  .Cleaning__container .Pets .room h5 {
    font-size: 28px;
  }
  .Cleaning__container .Pets .room .text {
    display: flex;
  }
  .Cleaning__container .Pets .room .text ul {
    font-size: 16px;
    margin: 0;
    padding: 0 10px;
  }
  .Cleaning__container .Pets .room .text ul p {
    font-size: 16px;
    width: 95%;
    height: auto;
  }
  .Cleaning__container .Pets .room .text ul .cat {
    margin-top: 0;
  }
  .Cleaning__container .Pets .room .text div img {
    margin: 5px;
  }
  .Cleaning__container .Pets .room__button {
    margin-top: 20px;
    justify-content: center;
    display: flex;
  }
  .Cleaning__container .Holiday {
    padding: 0 30px;
  }
  .Cleaning__container .Holiday .room {
    width: 95%;
    height: auto;
  }
  .Cleaning__container .Holiday .room span {
    position: absolute;
    background-color: #FFFFFF;
    top: 70px;
    left: -80px;
    width: 70px;
    height: 70px;
  }
  .Cleaning__container .Holiday .room h5 {
    font-size: 28px;
  }
  .Cleaning__container .Holiday .room .text {
    display: flex;
  }
  .Cleaning__container .Holiday .room .text ul {
    min-width: 350px;
    font-size: 16px;
    padding: 0 10px 0;
  }
  .Cleaning__container .Holiday .room .text ul p {
    width: 95%;
    height: auto;
  }
  .Cleaning__container .Holiday .room .text div {
    width: 60%;
  }
  .Cleaning__container .Holiday .room .text div img {
    margin: 0 auto;
  }
  .Cleaning__container .Holiday .room__button {
    margin-top: 20px;
    justify-content: center;
    display: flex;
  }
}
@media only screen and (max-width: 599px) {
  .Cleaning__container .logo {
    padding: 0;
    margin: 0 15px;
  }
  .Cleaning__container .logo .logo__img {
    width: 150px;
    height: 47px;
    margin: 15px 0 0;
  }
  .Cleaning__container .logo span {
    height: 47px;
    margin: 15px 0 0 30px;
  }
  .Cleaning__container .logo span h4 {
    position: relative;
    margin-left: 10px;
    width: 100%;
    font-size: 16px;
  }
  .Cleaning__container .logo span .type_img {
    margin: -10px 0 0 -20px;
    width: 40px;
    height: 40px;
    border-radius: 70px;
  }
  .Cleaning__container .MoveIn {
    margin: 25px 0;
  }
  .Cleaning__container .MoveIn .room {
    padding: 0 15px;
    margin-bottom: 25px;
  }
  .Cleaning__container .MoveIn .room div {
    width: 100%;
    height: auto;
    min-height: 250px;
  }
  .Cleaning__container .MoveIn .room div span {
    margin-top: -20px;
    margin-left: 30px;
    width: 40px;
    height: 40px;
  }
  .Cleaning__container .MoveIn .room div h5 {
    margin: 8px 0 0 30px;
    font-size: 20px;
  }
  .Cleaning__container .MoveIn .room div ul {
    padding: 10px 5px 10px 25px;
    font-size: 14px;
  }
  .Cleaning__container .MoveIn .room__button {
    margin-top: 10px;
  }
  .Cleaning__container .MoveIn .three div {
    height: 281px;
    margin-top: -10px;
    margin-bottom: -20px;
  }
  .Cleaning__container .rooms {
    padding: 0 15px;
    margin: 25px 0 0;
  }
  .Cleaning__container .rooms .room {
    justify-content: center;
    margin-bottom: 25px;
  }
  .Cleaning__container .rooms .room div {
    width: 100%;
    height: auto;
    min-height: 250px;
  }
  .Cleaning__container .rooms .room div span {
    margin-top: -20px;
    margin-left: 30px;
    width: 40px;
    height: 40px;
  }
  .Cleaning__container .rooms .room div h5 {
    z-index: 100;
    margin: 8px 0 0 30px;
    font-size: 20px;
  }
  .Cleaning__container .rooms .room div ul {
    margin: 0;
    width: 80%;
    padding: 10px 5px 10px 25px;
    font-size: 14px;
  }
  .Cleaning__container .rooms .room .three h5 {
    width: 80%;
    font-size: 18px;
    margin: 8px 0 0 40px;
  }
  .Cleaning__container .rooms .room .big_img {
    width: 97%;
    height: auto;
    min-height: 550px;
    border-radius: 5px;
  }
  .Cleaning__container .rooms .room__button {
    margin-top: -10px;
    justify-content: center;
    display: flex;
  }
  .Cleaning__container .DeepC {
    padding: 0 15px;
    margin: 15px 0;
  }
  .Cleaning__container .DeepC .text {
    margin: 0 0 5px;
  }
  .Cleaning__container .DeepC .text span {
    background-color: #FFFFFF;
    margin-left: 0;
    width: 50px;
    height: 50px;
  }
  .Cleaning__container .DeepC .text p {
    text-align: inherit;
    margin: 0 0 0 5px;
    font-size: 18px;
    width: 100%;
    height: auto;
  }
  .Cleaning__container .DeepC .room {
    height: auto;
    margin: 0;
  }
  .Cleaning__container .DeepC .room .text {
    display: block;
  }
  .Cleaning__container .DeepC .room .text ul {
    margin-top: 10px;
    width: 90%;
    font-size: 14px;
  }
  .Cleaning__container .DeepC .room .text div {
    width: 90%;
    height: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .Cleaning__container .DeepC .room .text div img {
    border-radius: 5px;
    width: 50%;
    height: 90%;
    margin: 10px;
    object-fit: cover;
  }
  .Cleaning__container .DeepC .room__button {
    margin-top: 20px;
    justify-content: center;
    display: flex;
  }
  .Cleaning__container .Pets {
    padding: 20px 15px 0;
  }
  .Cleaning__container .Pets .room {
    margin: 15px auto;
    width: 100%;
    height: auto;
  }
  .Cleaning__container .Pets .room span {
    background-color: #FFFFFF;
    height: 50px;
    width: 50px;
    top: -20px;
    left: 10px;
  }
  .Cleaning__container .Pets .room h5 {
    margin: 8px 0 0 45px;
    font-size: 16px;
  }
  .Cleaning__container .Pets .room .text {
    display: block;
  }
  .Cleaning__container .Pets .room .text ul {
    margin: 0;
    padding: 0 15px;
    width: 100%;
    font-size: 14px;
  }
  .Cleaning__container .Pets .room .text ul p {
    font-size: 14px;
    width: auto;
    height: auto;
  }
  .Cleaning__container .Pets .room .text ul .cat {
    object-fit: cover;
    object-position: bottom 60px;
    margin: 10px auto;
    float: top;
    width: 100%;
  }
  .Cleaning__container .Pets .room .text div {
    width: 90%;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .Cleaning__container .Pets .room .text div img {
    border-radius: 5px;
    width: 50%;
    height: 90%;
    margin: 10px;
    object-fit: cover;
  }
  .Cleaning__container .Holiday {
    padding: 0 15px;
  }
  .Cleaning__container .Holiday .room {
    width: 100%;
    height: auto;
    padding: 15px 5px;
  }
  .Cleaning__container .Holiday .room span {
    position: absolute;
    background-color: #FFFFFF;
    top: 75px;
    left: -130px;
    width: 50px;
    height: 50px;
  }
  .Cleaning__container .Holiday .room h5 {
    margin: 8px 0 0 45px;
    font-size: 16px;
  }
  .Cleaning__container .Holiday .room .text {
    display: block;
  }
  .Cleaning__container .Holiday .room .text ul {
    margin: 10px 0;
    padding: 0 15px;
    width: 100%;
    font-size: 14px;
  }
  .Cleaning__container .Holiday .room .text div {
    margin: 0 auto;
    width: 95%;
  }
  .Cleaning__container .Holiday .room .text div img {
    margin: 0 auto;
  }
  .Cleaning__container .Holiday .room__button {
    margin-top: 20px;
    justify-content: center;
    display: flex;
  }
}

