.homePage {
  font-family: 'Work Sans', sans-serif;
  margin: 0 auto 50px;

  .background {
    position: absolute;
    width: 100%;
    height: 103px;
    background: linear-gradient(180deg, rgba(43, 95, 137, 0.66) 0%, rgba(15, 133, 226, 0.00) 100%);


  }
  .background__two {
    width: 100%;
    height: 103px;
    background: linear-gradient(180deg, rgba(43, 95, 137, 0.66) 0%, rgba(15, 133, 226, 0.00) 100%);


  }

  .text__container {
    position: relative;
    max-width: 1340px;
    margin: -30px auto;


    .text {
      //margin-top: 230px;
      margin-top: -30px;

      h1 {
        text-align: center;
        color: #3C87C0;
        margin: 0 0 40px;
        font-size: 48px;
        font-weight: 700;
        line-height: 50px;

        span {
          color: #003156;
        }


      }

      p {
        max-width: 528px;
        display: flex;
        color: var(--Primery-Color-2, #003156);
        font-size: 20px;
        font-weight: 400;
        line-height: 24px; /* 120% */
      }
    }

    .Kitchen {
      margin-top: -30px;

      // margin-top: 230px;
      img {
        width: 776px;
        max-height: 486px;
        border-radius: 50px;
        object-fit: cover;
      }
    }
  }

  .ellipse {
    position: absolute;
    width: 200px;
    height: 326px;
    border-radius: 349px;
    background: rgba(17, 149, 255, 0.50);
    filter: blur(150px);

  }

  .ellipse__one {
    z-index: -1;
    top: -100;
    right: 0;

  }

  .ellipse__two {
    z-index: -1;
    top: 100px;
    left: -100px;
  }


  .stars__one {
    width: 100px;
    position: absolute;
    right: -50px;
    top: 50px
  }

  .stars__two {
    width: 100px;
    position: absolute;
    transform: rotate(180deg);
    left: 840px;
    top: 60px
  }

  @media only screen and (min-width: 600px) and (max-width: 1439px) {
    .text__container{

      margin: 0 auto;
      padding: 0 30px;

      .text{
        margin-top: -80px;
        h1{
          margin: 0;
          font-size: 32px;
          line-height: 35px;

        }
        p{
          font-size: 16px;
          line-height: 18px;
          margin-right: 10px;
        }
      br{
        line-height: 12px;
      }

      }

      .Kitchen {
        // margin-top: 230px;
        margin-top: -80px;
        img {
          width: 100%;
          height: auto;
          max-height: 350px;
          border-radius: 30px;
          object-fit: cover;
        }
      }
    }

  }
  @media only screen and  (max-width: 599px) {
    .text__container{

      margin: 10px auto;
      padding: 0 15px;

      .text{
        margin-top: -100px;
        margin-bottom: 10px;
        h1{
          margin: 0 0 10px;
          font-size: 28px;
          line-height: 30px;

        }
        p{
          font-size: 14px;
          line-height: 16px;
          margin-right: 0;
        }
        br{
          line-height: 12px;
        }

      }

      .Kitchen {
        margin: 0;
        // margin-top: 230px;
        img {
          border-radius: 20px;
          width: 100%;
          height: auto;
          max-height: 350px;
          object-fit: cover;
        }
      }
    }
    .ellipse__one{
      width: 100px;
      top: 200px;
      left: -150px;
    }
    .ellipse__two{
      width: 100px;
      position: absolute;
      top: 100px;
      right: 0;
    }
    .stars__two{
      height: 100px;
      width: 50px;
      top: 200px;
      left: 840px;
    }
    .stars__one{
      left: 0;
      right: 0;
      z-index: 10;
      height: 100px;
      width: 50px;
      top: 130px;
    }
  }
}
